import React from "react"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import SecondaryHeader from "../components/header/header-secondary"
import device from "../components/device"

const Wrapper = styled.section`
  .secondary-header-wrapper {
    background: var(--darkgrey);
    color: var(--white);
  }
  .content {
    max-width: 1500px;
    width: 70%;
    margin: 5rem auto 10rem auto;
    display: flex;
    ${device.medium`flex-direction: column;`}
    ${device.small`width: var(--spread);`}
  }

  iframe {
    height: 50vh;
    width: 100%;

    @media (min-width: 992px) {
      height: 80vh;
    }
  }
`

const Info = styled.div`
  flex: 1;
  .heading-wrapper {
    .heading {
      line-height: 1;
      font-size: 3rem;
      ${device.small`display: block; line-height: 1; font-size: 2.5rem;`}
      .secondary-heading {
        margin-left: 3rem;
      }
    }
  }
  .text {
    margin: 2rem 0;
    width: 70%;
    font-size: 0.9rem;
    ${device.small`width: 100%;`}
  }
  .social-links {
    a {
      margin-right: 1.5rem;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
  .phone {
    margin: 2rem 0;
    line-height: 1.6;
    .toll-free,
    .local {
      margin: 1rem 0;
    }
    .type {
      display: block;
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 0.8rem;
    }
    .number {
      letter-spacing: 2px;
      color: var(--green);
    }
  }
`

const NinePassengerVan = ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <title>14 Passenger Van</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Wrapper>
        <div className="secondary-header-wrapper">
          <SecondaryHeader />
        </div>
        <div className="content">
          <iframe src="https://king-transportation-360-files.netlify.app/static/14-passenger-van/app-files/index.html"></iframe>
        </div>
      </Wrapper>
    </Layout>
  )
}

export default NinePassengerVan
